import classNames from 'classnames';
import { ReactNode } from 'react';

export enum ButtonVariant {
  Primary = 'Primary',
  PrimaryNegative = 'PrimaryNegative',
  Secondary = 'Secondary',
  SecondaryNegative = 'SecondaryNegative',
}

interface ButtonProps {
  /**
   * Variants like Primary, Secondary, ...
   */
  variant?: ButtonVariant;
  /**
   * Button contents
   */
  label: string;
  /**
   * Optional icon
   */
  icon?: ReactNode;
  /**
   * Optional click handler
   */
  className?: string;
  onClick?: () => void;
}

/**
 * Primary UI component for user interaction
 */
export const Button = ({
  variant = ButtonVariant.Primary,
  label,
  icon,
  className,
  ...props
}: ButtonProps) => {
  return (
    <button
      type="button"
      className={classNames(
        'uppercase font-medium text-13 leading-140 tracking-8 inline-flex items-center',
        {
          'px-5 py-2.5 rounded-xl md:rounded-3xl':
            variant === ButtonVariant.Primary || ButtonVariant.PrimaryNegative,
        },
        {
          'bg-blue-500 hover:bg-blue-800 text-white':
            variant === ButtonVariant.Primary,
        },
        {
          'bg-white text-blue-500 hover:text-blue-800':
            variant === ButtonVariant.PrimaryNegative,
        },
        {
          'text-iceblue-500 hover:text-iceblue-800':
            variant === ButtonVariant.Secondary,
        },
        {
          'text-white': variant === ButtonVariant.SecondaryNegative,
        },
        className,
      )}
      {...props}>
      {label}
      {icon}
    </button>
  );
};
