import Link from 'next/link';
import { Menu, Settings } from '@lib/types';
import Nav from './nav';
import { ReactNode, useEffect, useState } from 'react';
import Footer from './footer';
import { TrackingConsent } from './tracking';
import classNames from 'classnames';

type LayoutProps = {
  children: ReactNode;
  isPreview?: boolean;
  menu: Menu;
  menuFooter: Menu;
  menuFooterSecondary: Menu;
  settings: Settings;
};

export default function Layout({
  children,
  isPreview = false,
  menu,
  menuFooter,
  menuFooterSecondary,
  settings,
}: LayoutProps): JSX.Element {
  const [sticky, setSticky] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', isSticky, { passive: true });
    return () => {
      window.removeEventListener('scroll', isSticky);
    };
  }, []);

  function isSticky() {
    const scrollTop = window.scrollY;
    setSticky(scrollTop > 100);
  }

  return (
    <>
      {isPreview && (
        <div className="fixed inset-x-0 top-0 z-50 bg-blue-100 text-blue-800 text-15">
          <div className="flex justify-between max-w-screen-4xl mx-auto px-6 md:px-12 py-1">
            <span>Vorschaumodus</span>
            <Link href="/api/exit-preview">Beenden</Link>
          </div>
        </div>
      )}
      <div className="flex flex-col min-h-screen">
        <Nav
          menu={menu}
          sticky={sticky}
          trackingConsent={
            settings.googleAnalytics.consentInfo ? (
              <TrackingConsent
                info={settings.googleAnalytics.consentInfo}
                acceptAllLabel={settings.googleAnalytics.acceptAllLabel}
                declineAllLabel={settings.googleAnalytics.declineAllLabel}
              />
            ) : null
          }
        />
        <main
          className={classNames('flex-1', {
            // keep in sync with nav height and container padding
            'mt-[44px] md:mt-[28px] lg:mt-[46px]': sticky,
            'mt-[62px] md:mt-[46px] lg:mt-[112px]': !sticky,
          })}>
          {children}
        </main>
        <Footer
          menu={menuFooter}
          menuSecondary={menuFooterSecondary}
          {...settings.footer}
        />
      </div>
    </>
  );
}
