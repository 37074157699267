import { ContainerSize, Menu, SettingsFooter } from '@lib/types';
import Link from 'next/link';
import Container from './container';
import { Logo } from './icons';
import { buildImageUrl } from '@lib/image';
import Image from 'next/image';
import { Fragment } from 'react';

type FooterProps = {
  menu: Menu;
  menuSecondary: Menu;
} & SettingsFooter;

export default function Footer({
  menu,
  menuSecondary,
  address,
  socialLinks,
}: FooterProps) {
  return (
    <footer>
      <Container size={ContainerSize.wide}>
        <div className="grid grid-cols-12 gap-x-1.5 md:gap-x-6 pt-20 bg-iceblue-200 rounded-xl md:rounded-3xl">
          <div className="col-span-8 col-start-3 md:col-span-5 lg:col-span-3 md:col-start-0 md:pl-16">
            <Logo className="h-auto w-[180px]" />
          </div>
          <div className="col-span-8 col-start-3 md:col-span-3 lg:col-span-2 md:col-start-0 my-16 md:my-0">
            <div className="flex flex-col gap-y-4 md:gap-y-5">
              {menu.items.map((item, i) => (
                <Link
                  key={i}
                  className="block col-span-3 font-bold text-16 md:text-13 leading-[1.6] tracking-8 uppercase w-full"
                  href={item.href}
                  target={item.target}>
                  {item.title}
                </Link>
              ))}
            </div>
          </div>
          <div className="col-span-8 col-start-3 md:col-span-4 lg:col-span-7 md:col-start-0">
            <address
              className="text-blue-800 not-italic address"
              dangerouslySetInnerHTML={{ __html: address }}
            />
            <div className="flex gap-3 mt-8">
              {socialLinks.map((socialLink) => (
                <a
                  key={socialLink.id}
                  href={socialLink.url}
                  target="_blank"
                  rel="noreferrer">
                  <Image
                    src={buildImageUrl(
                      socialLink.image.thumbnail
                        ? socialLink.image.thumbnail.url
                        : socialLink.image.url,
                    )}
                    alt={socialLink.image.alternativeText}
                    width={26}
                    height={26}
                  />
                </a>
              ))}
            </div>
          </div>
          <div className="col-span-12 border-t border-white mt-8 md:mt-20 pt-8 md:pt-4 pb-16 md:pb-5">
            <div className="grid grid-cols-12 gap-x-1.5 md:gap-x-6">
              <div className="col-span-8 col-start-3 md:col-span-12 md:col-start-0 md:px-20 md:text-right text-16 md:text-13 leading-[1.6] md:leading-7 text-blue-300 flex md:inline-block flex-col gap-[5px]">
                {menuSecondary.items.map((item, i) => (
                  <Fragment key={i}>
                    {i > 0 && <span className="mx-4 hidden md:inline">|</span>}
                    <Link
                      className="hover:underline block md:inline"
                      href={item.href}
                      target={item.target}>
                      {item.title}
                    </Link>
                  </Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </footer>
  );
}
