import Script from 'next/script';
import { getCookie, setCookie } from 'cookies-next';
import { useEffect, useState } from 'react';
import { Button, ButtonVariant } from './button';

export function Tracking({ trackingId }: { trackingId: string }) {
  const consent = getCookie('localConsent');

  return (
    <>
      <Script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${trackingId}`}
      />
      <Script
        id="gtag"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());
          gtag('config', '${trackingId}');
          gtag('consent', 'default', {
            ad_storage: 'denied',
            analytics_storage: 'denied',
          });
          `,
        }}
      />
      {consent === true && (
        <Script
          id="gconsent"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            gtag('consent', 'update', {
              ad_storage: 'granted',
              analytics_storage: 'granted',
            });
          `,
          }}
        />
      )}
    </>
  );
}

export function TrackingConsent({
  info,
  acceptAllLabel,
  declineAllLabel,
}: {
  info: string;
  acceptAllLabel: string;
  declineAllLabel: string;
}) {
  const [consent, setConsent] = useState(true);
  const maxAge = 60 * 60 * 24 * 365;

  useEffect(() => {
    setConsent(getCookie('localConsent') === true);
  }, []);

  const acceptCookie = () => {
    setConsent(true);
    setCookie('localConsent', 'true', { maxAge });
    gtag('consent', 'update', {
      ad_storage: 'granted',
      analytics_storage: 'granted',
    });
  };

  const denyCookie = () => {
    setConsent(true);
    setCookie('localConsent', 'false', { maxAge });
  };

  if (consent === true) {
    return null;
  }

  return (
    <div
      className={`flex w-full items-center border-b-[1px] border-blue-200 ${
        consent ? 'hidden' : ''
      }`}>
      <div className="flex w-full max-w-screen-4xl mx-auto flex-col md:flex-row md:items-center py-3 px-6 md:px-12 gap-3">
        <div
          className="flex-1 prose tracking-consent"
          dangerouslySetInnerHTML={{ __html: info }}
        />
        <div className="flex-0 hidden md:flex gap-3">
          <Button
            onClick={denyCookie}
            label={declineAllLabel}
            variant={ButtonVariant.Primary}
          />
          <Button
            onClick={acceptCookie}
            label={acceptAllLabel}
            variant={ButtonVariant.Primary}
          />
        </div>
        <div className="flex-0 flex md:hidden gap-3 w-full justify-between">
          <Button
            className="px-0"
            onClick={denyCookie}
            label={declineAllLabel}
            variant={ButtonVariant.PrimaryNegative}
          />
          <Button
            className="px-0"
            onClick={acceptCookie}
            label={acceptAllLabel}
            variant={ButtonVariant.PrimaryNegative}
          />
        </div>
      </div>
    </div>
  );
}
