import classNames from 'classnames';
import { Popover, Disclosure, Transition } from '@headlessui/react';
import { Fragment, MouseEvent } from 'react';
import Link from 'next/link';
import { ContainerSize, Menu } from '@lib/types';
import { CloseIcon, DownIcon, GlobeIcon, HamburgerIcon, Logo } from './icons';
import Container from './container';
import { useRouter } from 'next/router';
import i18nConfig from '../i18n';
import { setCookie } from 'cookies-next';

type NavProps = {
  menu: Menu;
  sticky: boolean;
  trackingConsent?: React.ReactNode;
};

export default function Nav({ menu, sticky, trackingConsent }: NavProps) {
  const router = useRouter();
  const { pathname, asPath, query } = router;
  const locales = router.locales || [];
  const currentLocale = router.locale || router.defaultLocale;

  const handleLangSwitch = (e: MouseEvent, nextLocale: string) => {
    e.preventDefault();
    setCookie('NEXT_LOCALE', nextLocale); // this will skip the automatic locale detection based on browser lang or ip location
    router.push({ pathname, query }, asPath, { locale: nextLocale });
  };

  return (
    <Popover className="bg-white fixed w-full z-20">
      {trackingConsent}
      <div className="max-w-screen-4xl mx-auto">
        <div
          className={classNames(
            'transition-[padding] ease-in-out duration-200 px-6 md:px-12',
            {
              'py-[18px] lg:pt-8 lg:pb-[22px]': sticky,
              'pt-8 pb-[22px] lg:pt-[80px] lg:pb-[40px]': !sticky,
            },
          )}>
          <div className="relative grid grid-cols-12 gap-1.5 md:gap-6 items-center">
            <div className="col-span-9 lg:col-span-3 z-30">
              <Link href="/" className="flex">
                <span className="sr-only">Distalmotion</span>
                <Logo
                  className="h-10 w-auto"
                  classNameText={classNames(
                    'transition-opacity ease-in-out duration-200',
                    {
                      'opacity-0 lg:opacity-100': sticky,
                    },
                    {
                      'opacity-1': !sticky,
                    },
                  )}
                />
              </Link>
            </div>
            <div className="col-span-3 col-start-10 justify-self-end -my-2 -mr-2 lg:hidden">
              <Popover.Button className="inline-flex items-center justify-center rounded-xl md:rounded-3xl bg-white p-2 text-blue-800 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                <span className="sr-only">Open menu</span>
                <HamburgerIcon
                  className="w-[28px] text-iceblue-500"
                  aria-hidden="true"
                />
              </Popover.Button>
            </div>
            <div className="hidden lg:block absolute inset-x-0 z-0">
              <div className="-mx-6 md:-mx-12">
                <Container size={ContainerSize.wide}>
                  <div className="grid grid-cols-12 gap-1.5 md:gap-6">
                    <div className="col-start-4 col-span-9 lg:flex lg:flex-1 lg:items-center lg:justify-between text-13 leading-140 tracking-8 font-bold mt-[6px]">
                      <Popover.Group as="nav" className="flex space-x-10">
                        {menu.items.slice(0, -1).map((item, index) => {
                          if (item.children) {
                            return (
                              <Popover key={index} className="relative">
                                {({ open }) => (
                                  <>
                                    <Popover.Button
                                      className={classNames(
                                        'group inline-flex items-center rounded-xl md:rounded-3xl bg-white font-bold text-blue-800 tracking-8 uppercase outline-none',
                                      )}>
                                      <span>{item.title}</span>
                                      <DownIcon
                                        className={`${
                                          open ? 'rotate-180 transform' : ''
                                        } ml-2 mt-1 h-1`}
                                        aria-hidden="true"
                                      />
                                    </Popover.Button>

                                    <Transition
                                      as={Fragment}
                                      enter="transition ease-out duration-200"
                                      enterFrom="opacity-0 translate-y-1"
                                      enterTo="opacity-100 translate-y-0"
                                      leave="transition ease-in duration-150"
                                      leaveFrom="opacity-100 translate-y-0"
                                      leaveTo="opacity-0 translate-y-1">
                                      <Popover.Panel className="absolute left-0 z-20 mt-3 max-w-xs transform -translate-x-8">
                                        <div className="overflow-hidden rounded-xl md:rounded-3xl rounded-t-none border border-blue-100 border-t-0">
                                          <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-6 sm:p-8 text-11 font-medium">
                                            {item.children?.map((item) => (
                                              <Link
                                                key={item.title}
                                                href={item.href}
                                                target={item.target}
                                                className="-m-3 block p-3">
                                                <span className="text-blue-800 border-color-800 hover:border-b hover:font-bold uppercase">
                                                  {item.title}
                                                </span>
                                              </Link>
                                            ))}
                                          </div>
                                        </div>
                                      </Popover.Panel>
                                    </Transition>
                                  </>
                                )}
                              </Popover>
                            );
                          }

                          return (
                            <Link
                              key={index}
                              className=" text-blue-800 uppercase font-bold"
                              href={item.href}
                              target={item.target}>
                              {item.title}
                            </Link>
                          );
                        })}
                      </Popover.Group>
                    </div>
                  </div>
                </Container>
              </div>
            </div>
            <div className="hidden lg:flex col-start-11 col-span-2 lg:flex-1 lg:items-center lg:justify-end lg:gap-1 text-13 leading-140 tracking-8 font-bold items-center uppercase text-iceblue-500 z-30 mt-[6px]">
              {/* deactivated until they are ready with content */}
              {/* <Popover className="relative">
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={classNames(
                        'group rounded-xl md:rounded-3xl font-bold uppercase outline-none py-2.5 -my-2.5 px-5',
                        !open &&
                          'bg-white hover:bg-iceblue-100 hover:text-iceblue-500',
                        open &&
                          'bg-iceblue-600 text-white hover:bg-iceblue-600 hover:text-white',
                      )}>
                      <GlobeIcon className="absolute -top-0.5 w-6 h-6" />{' '}
                      <span className="ml-6 pl-1">{currentLocale}</span>
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1">
                      <Popover.Panel className="absolute left-0 z-20 mt-3 max-w-xs transform -translate-x-3">
                        <div className="overflow-hidden rounded-xl md:rounded-3xl rounded-t-none md:rounded-t-none border border-blue-100 border-t-0">
                          <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-6 sm:p-8 text-11 font-medium">
                            {locales.map((locale) => (
                              <Link
                                key={locale}
                                href={{
                                  pathname: router.pathname,
                                  query: router.query,
                                }}
                                as={router.asPath}
                                locale={locale}
                                onClick={(e) => handleLangSwitch(e, locale)}
                                className="-m-3 block p-3">
                                <span
                                  className={classNames(
                                    'text-blue-800 border-color-800 hover:border-b hover:font-bold uppercase whitespace-nowrap',
                                    {
                                      'border-b font-bold':
                                        locale === currentLocale,
                                    },
                                  )}>
                                  {Object.entries(i18nConfig.localesLabel)
                                    .filter(([key]) => key === locale)
                                    .map(([key, value]) => value)
                                    .join('')}
                                </span>
                              </Link>
                            ))}
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover> */}
              {menu.items.length > 0 && (
                <Link
                  className="py-2.5 -my-2.5 px-5 flex-shrink-0 hover:bg-iceblue-100 rounded-xl md:rounded-3xl"
                  href={menu.items[menu.items.length - 1].href}>
                  {menu.items[menu.items.length - 1].title}
                </Link>
              )}
            </div>
          </div>
        </div>

        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95">
          <Popover.Panel
            focus
            className="fixed inset-x-0 top-0 bottom-0 origin-top-right transform transition lg:hidden bg-iceblue-200 text-blue-800 z-30">
            {({ close }) => (
              <div className="">
                <div
                  className={classNames('px-6 md:px-12', {
                    'pt-8 pb-[22px]': sticky,
                    'pb-[22px] pt-8 lg:pt-[80px] lg:pb-[40px]': !sticky,
                  })}>
                  <div className="flex items-start justify-end">
                    <div className={classNames('-mr-1', { '-mt-3': sticky })}>
                      <Popover.Button className="inline-flex items-center justify-center text-iceblue-500 p-2 focus:outline-none">
                        <span className="sr-only">Close menu</span>
                        <CloseIcon className="w-[20px]" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                </div>
                <div className="py-6 px-16">
                  {menu.items.map((item, index) => {
                    if (item.children) {
                      return (
                        <Disclosure key={index}>
                          {({ open }) => (
                            <>
                              <Disclosure.Button className="flex items-center font-bold text-16 leading-140 tracking-8 uppercase w-full py-4">
                                <span>{item.title}</span>
                                <DownIcon
                                  className={`${
                                    open ? 'rotate-180 transform' : ''
                                  } ml-2 mt-1 w-[12px]`}
                                  aria-hidden="true"
                                />
                              </Disclosure.Button>
                              <Disclosure.Panel className="space-y-4 font-medium tracking-4 pt-2 pb-4">
                                {item.children?.map((item, index) => {
                                  return (
                                    <Popover.Button
                                      key={index}
                                      as={Link}
                                      className="block"
                                      href={item.href}
                                      target={item.target}>
                                      {item.title}
                                    </Popover.Button>
                                  );
                                })}
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      );
                    }

                    return (
                      <Popover.Button
                        key={index}
                        as={Link}
                        className="block font-bold text-16 leading-140 tracking-8 text-blue-800 uppercase py-4"
                        href={item.href}
                        target={item.target}>
                        {item.title}
                      </Popover.Button>
                    );
                  })}

                  <div className="mt-16">
                    {locales.map((locale) => (
                      <Link
                        key={locale}
                        href={{
                          pathname: router.pathname,
                          query: router.query,
                        }}
                        as={router.asPath}
                        locale={locale}
                        onClick={(e) => {
                          handleLangSwitch(e, locale);
                          close();
                        }}
                        className="-m-3 block p-3">
                        <span
                          className={classNames(
                            'text-blue-800 border-color-800 hover:border-b hover:font-bold uppercase text-13 whitespace-nowrap',
                            { 'border-b font-bold': locale === currentLocale },
                          )}>
                          {Object.entries(i18nConfig.localesLabel)
                            .filter(([key]) => key === locale)
                            .map(([key, value]) => value)
                            .join('')}
                        </span>
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </Popover.Panel>
        </Transition>
      </div>
    </Popover>
  );
}
