import { vimeoPresets } from '@components/vimeo';

export type Page = {
  id: number;
  slug: string;
  title: string;
  sections: Section[];
  url: string;
  locale: string;
  seo: SeoData | null;
};

export type Section = (
  | RichText
  | ImageText
  | VideoText
  | Image
  | Video
  | Cards
  | HeroStatic
  | HeroVideo
  | Quote
  | TitleParagraphLinks
  | Team
  | PageTitle
  | Contact
  | Events
  | Vimeo
  | HTML
) & {
  sectionName: string;
};

export type RichText = {
  title?: string;
  html: string;
};

export type PageTitle = {
  title: string;
};

export type Quote = {
  title: string;
  author: string;
  authorDescription: string;
};

export type TitleParagraphLinks = {
  title: boolean;
  paragraph: string;
  links: Link[];
};

export type Link = {
  text: string;
  url: string;
};

type TeaserText = {
  title: string;
  subTitle: string;
  link: Link | null;
  layout: 'ImageLeft' | 'ImageRight' | null;
  paragraph: string | null;
};

export type ImageText = TeaserText & {
  image: ImageData;
  vimeoUrl?: never;
  videoPreset?: never;
  videoStartAtSecond?: never;
};

export type VideoText = TeaserText & {
  image?: never;
  vimeoUrl: string;
  videoPreset: keyof typeof vimeoPresets | null;
  videoStartAtSecond: number | null;
};

export type Image = {
  image: ImageData;
};

export type Video = {
  video: VideoData;
};

export type VideoData = {
  mime: string;
  rawData: any;
  thumbnail: string;
  title: string;
  url: string;
};

export type ImageFormat = {
  width: number;
  height: number;
  url: string;
};

export type ImageData = {
  id: number;
  alternativeText: string;
  thumbnail: ImageFormat;
  small: ImageFormat | null;
  medium: ImageFormat | null;
  large: ImageFormat | null;
  xlarge: ImageFormat | null;
  xxlarge: ImageFormat | null;
  hash: string;
  ext: string;
  mime: string;
  name: string;
} & ImageFormat;

export type Cards = {
  elements: Card[];
};

export type Card = {
  overline: string;
  title: string;
  html: string;
  link: Link | null;
};

export type FocalPoint =
  | 'bottom'
  | 'center'
  | 'left'
  | 'leftBottom'
  | 'leftTop'
  | 'right'
  | 'rightBottom'
  | 'rightTop'
  | 'top'
  | 'custom';

type Hero = {
  title: string;
  overline?: string;
};

export type HeroStatic = Hero & {
  vimeoUrl?: never;
  cover: ImageData;
  backgroundFocalpoint: FocalPoint;
  customFocalpoint: string;
  videoStartAtSecond?: never;
};

export type HeroVideo = Hero & {
  vimeoUrl: string;
  cover?: never;
  backgroundFocalpoint?: never;
  customFocalpoint: never;
  videoStartAtSecond?: number | null;
};

export type Menu = {
  items: MenuItem[];
};

export type MenuItem = {
  title: string;
  href: string;
  target: string;
  children?: MenuItem[];
};

export enum ContainerSize {
  wide = 'wide',
  full = 'full',
}

export type Settings = {
  footer: SettingsFooter;
  texts: Record<string, string>;
  googleAnalytics: SettingsGoogleAnalytics;
};

export type SettingsFooter = {
  address: string;
  socialLinks: {
    id: number;
    url: string;
    image: ImageData;
  }[];
};

export type SettingsGoogleAnalytics = {
  trackingId?: string;
  consentInfo?: string;
  acceptAllLabel: string;
  declineAllLabel: string;
};

export type SeoSocialData = {
  socialNetwork: 'Facebook' | 'Twitter';
  title: string;
  description: string;
  image: ImageData | null;
};

export type SeoData = {
  metaTitle: string;
  metaDescription: string;
  metaImageUrl: string;
  metaSocial: SeoSocialData[];
  keywords?: string;
  metaRobots?: string;
  structuredData?: string;
  metaViewport?: string;
  canonicalURL?: string;
};

export type Employee = {
  id: number;
  firstName: string;
  lastName: string;
  middleName?: string;
  titlesSalutation?: string;
  suffix?: string;
  description: string;
  portrait: ImageData | null;
  bio: string;
  linkedIn: string;
};

export type Team = {
  title: string;
  lead: string;
  employees?: Employee[];
};

export type Event = {
  id: number;
  title: string;
  start: string;
  end: string;
  location: string;
  description?: string;
};

export type Events = {
  title: string;
  paragraph: string;
  events: Event[];
};

export type Contact = {
  id: number;
  title: string;
  paragraph: string;
  successMsg: string;
  errorMsg: string;
  showBg: boolean;
  function: string[];
  surgicalSpecialty: string[];
};

export type News = {
  id: string;
  slug: string;
  title: string;
  intro: string;
  publishDate: string;
  image: ImageData;
  content: Section[];
  seo: SeoData | null;
  locale: string | null;
};

export type Button = {
  links: Link[];
};

export type Vimeo = {
  vimeoUrl: string;
  videoPreset?: keyof typeof vimeoPresets | null;
  videoStartAtSecond?: number | null;
};

export type Highlight = {
  text: string;
};

export type MetaPagination = {
  page: number;
  pageSize: number;
  pageCount: number;
  total: number;
};

export type HTML = {
  html: string;
};
