import classNames from 'classnames';
import { ContainerSize } from '@lib/types';
import { ReactNode } from 'react';

type ContainerProps = {
  children: ReactNode;
  size?: ContainerSize;
};

export default function Container({
  children,
  size = ContainerSize.full,
}: ContainerProps) {
  return (
    <div
      className={classNames(
        'px-6 md:px-12 py-8 md:py-12 mx-auto w-full',
        {
          'max-w-screen-3xl': size === ContainerSize.wide,
        },
        {
          'max-w-screen-4xl': size === ContainerSize.full,
        },
      )}>
      {children}
    </div>
  );
}
